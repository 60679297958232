<template>
      <Header></Header>
      
      <div class="divPanel">
        <ClientBilanLoadCsv @BilanLoaded="onBilanLoaded"></ClientBilanLoadCsv>

        Date du Bilan:
        <datepicker :default-value="new Date()" :key="new Date()" v-model="csvDatePickerValue">
                        </datepicker>
      </div>
      <kendo-datasource   ref="datasource1"
                            :page-size='20'
                            :server-paging="false"
                            :server-filtering="false"
                            :transport-read="transportRead"
                            :transport-update="transportUpdate"
                            :transport-destroy="transportDestroy"
                            :transport-create="transportCreate"
                            :transport-parameter-map="parameterMap"
                            :schema-model-id="'id'"
                            :batch='false'
                            
                            :schema-model-fields="dsSchemaFields">
        </kendo-datasource>

        <kendo-grid ref="adminUsersGrid"
                    :data-source-ref="'datasource1'"
                    :sortable-allow-unsort="true"
                    :sortable-show-indexes="true"
                    
                    :editable="'popup'"
                    :pageable="false"
                    :toolbar="['create', 'search' ]"
                    :selectable= "true"
                    v-on:change="onChange"
        >
            <kendo-grid-column :field="'dateBilan'" :title="'Date Bilan'" :format="'{0:dd/MM/yyyy}'" :width="120" :hidden="false"></kendo-grid-column>
            <kendo-grid-column :field="'cashEtEquivalent'" :title="'Montant et equivalent'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'achatsRevendusEtAchatsConsommes'" :title="'achats Revendus Et Achats Consommes'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'actifNonCirculantAuto'" :title="'actif Non Circulant Auto'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'ca'" :title="'Chiffre affaire'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'chargesInteretNet'" :title="'charges Interet Net'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'clientsEtComptesRattaches'" :title="'clients Et Comptes Rattaches'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'createur'" :title="'Createur'" :hidden="false" ></kendo-grid-column>
            <kendo-grid-column :field="'dateCreation'" :title="'Date creation'" :format="'{0:dd/MM/yyyy}'" :hidden="false" ></kendo-grid-column>
            <kendo-grid-column :field="'dettesACourtTerme'" :title="'dettes A Court Terme'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'dettesAMoyenEtLongTermeAuto'" :title="'dettes A Moyen Et Long Terme'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'dotationExploitation'" :title="'dotation Exploitation'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'fondDeRoulementAuto'" :title="'fond De Roulement Auto'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'fondsPropres'" :title="'fonds Propres'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'fournisseursEtComptesRattaches'" :title="'fournisseurs Et Comptes Rattaches'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'pourcentageEbitdaAuto'" :title="'pourcentage Ebitda Auto'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'resultatAvantImpots'" :title="'resultat avant impôts'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'resultatExploitation'" :title="'resultat Exploitation'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'resultatNonCourant'" :title="'resultat Non Courant'" :hidden="true"></kendo-grid-column>
            <!-- <kendo-grid-column :field="'societeId'" :title="'Date'"></kendo-grid-column> -->
            <kendo-grid-column :field="'stock'" :title="'stock'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'totalActif'" :title="'total Actif'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :field="'totalActifCirculant'" :title="'total Actif Circulant'" :hidden="true"></kendo-grid-column>
            <kendo-grid-column :command="['edit', 'destroy']" :title="'&nbsp;'" :width="190"></kendo-grid-column>
        </kendo-grid>
</template>

<script>
import Header from "./Header.vue";
import CRUDService from "../1-common-components/crud.service";
import { Button } from '@progress/kendo-vue-buttons';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui';
import axios from 'axios';
import authHeader from '../0-auth-components/auth-header.js';
import {_} from 'vue-underscore';
import LoadCsv from "./LoadCsv.vue";
import CsvToDb from "./ClientCSVtoDB.service";

export default {
  name: "ClientBilan",
  components: {
        Header, 
        'k-button': Button,
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn,
        'kendo-datasource': KendoDataSource,
        'ClientBilanLoadCsv' : LoadCsv,
        'datepicker': DatePicker,
    },
    mounted() {
        this.log("----------- mounted()");

    },
  props:   ['societeId'],
  data() {
    return {
      csvDatePickerValue : new Date(),
      dsSchemaFields: {
                dateBilan: { type: "date", validation: { required: false } },
                cashEtEquivalent: { type: "number", validation: { required: false } },
                achatsRevendusEtAchatsConsommes: { type: "number", validation: { required: false } },
                actifNonCirculantAuto: { type: "number", validation: { required: false } },
                ca: { type: "number", validation: { required: false } },
                cashEtEquivalent: { type: "number", validation: { required: false } },
                chargesInteretNet: { type: "number", validation: { required: false } },
                clientsEtComptesRattaches: { type: "number", validation: { required: false } },
                createur: { type: "string", validation: { required: false }, editable:false },
                dateBilan: { type: "date", validation: { required: false } },
                dateCreation: { type: "date", validation: { required: false },editable:false },
                dettesACourtTerme: { type: "number", validation: { required: false } },
                dettesAMoyenEtLongTermeAuto: { type: "number", validation: { required: false } },
                dotationExploitation: { type: "number", validation: { required: false } },
                fondDeRoulementAuto: { type: "number", validation: { required: false } },
                fondsPropres: { type: "number", validation: { required: false } },
                fournisseursEtComptesRattaches: { type: "number", validation: { required: false } },
                pourcentageEbitdaAuto: { type: "number", validation: { required: false } },
                resultatAvantImpots: { type: "number", validation: { required: false } },
                resultatExploitation: { type: "number", validation: { required: false } },
                resultatNonCourant: { type: "number", validation: { required: false } },
                societeId: { type: "number", validation: { required: false } },
                stock: { type: "number", validation: { required: false } },
                totalActif: { type: "number", validation: { required: false } },
                totalActifCirculant: { type: "number", validation: { required: false } },
            },
    };
  },
  computed: {
        getCsvDatePicker: function()
        {
            return this.csvDatePickerValue;
        },
    },
  methods: {
        onChange: function(ev) {
                // store the selected grid item in data
                this.log("onChange event: ");
                var rows = ev.sender.select();
                var grid = ev.sender;
                var vueContext = this;
                rows.each(function(e) {
                    var dataItem = grid.dataItem(this);
                    vueContext.selectedItem = dataItem;
                    this.log(dataItem);
                })
        },
        parameterMap: function(options, operation) {
            this.log(options);
            this.log(operation);
            if (operation !== 'read' && options.models) {
                return { models: kendo.stringify(options.models) };
            }
        },
        getBySocietyId(data,endpoint) {
          var API_URL = process.env.VUE_APP_API_URL;
          let id = data;
          if(typeof data === 'object' && data !== null)
          {
            id = data.id;
          }
          
          var url = API_URL + endpoint+'/bysociete/'+id
          this.log("---------- get url: "+url);
          return axios.get(url, { headers: authHeader() });
        },
        transportRead: function(e)
        {
            this.log("------transportRead");
            this.log(e);
            this.getBySocietyId(this.societeId,'bilanfinancierclient').then(
                (response) => {
                    this.log(response.data);
                    this.gridData = response.data;
                    e.success(this.gridData);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
        transportUpdate: function(e)
        {
            this.log("------transportUpdate");
            this.log(e);
            CRUDService.update(e.data,'bilanfinancierclient').then(
                (response) => {
                    this.log(response.data);
                    e.success(response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        transportCreate: function(e)
        {
            this.log("------transportCreate");
            this.log(e);
            e.data.Application = "ClientApplication"
            e.data.societeId = this.societeId;
            CRUDService.create(e.data,'bilanfinancierclient').then(
                (response) => {
                    this.log(response.data);
                    e.success(response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        transportDestroy: function(e)
        {
            this.log("------transportDestroy");
            this.log(e);
            CRUDService.delete(e.data,'bilanfinancierclient').then(
                (response) => {
                    this.log(response.data);
                    e.success(this.gridData);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        onBilanLoaded: function(e)
        {
            var event = e;
            var ref = this;
            var jsonToAdd = {};
            jsonToAdd["societeId"] = ref.societeId;
            jsonToAdd["dateBilan"] = new Date(this.getCsvDatePicker).toISOString().slice(0, 19);
            CsvToDb.CSVToDBFunction('bilanfinancierclient',e.target.files[0],jsonToAdd).then(function(){
              $(event.target).val("");
              ref.$refs.adminUsersGrid.kendoWidget().dataSource.read()
              ref.$toast.add({severity:'success', summary: 'Bilan chargé!', detail:"Le bilan s'est chargé avec succés", life: 3000});
            }).catch(function(){
              $(event.target).val("");
              this.$toast.add({severity:'error', summary: 'Erreur', detail:"Erreur dans le chargement de bilan", life: 3000});
            });
        }
  },
};
</script>
